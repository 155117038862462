import { useState, useEffect, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';
import * as REST from '~services/rest';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

export const useGet = (
  {
    name = '',
    cacheData = null,
    params,
    requiresAuthentication = false,
    shouldExecute = true,
    callback,
    mockFileName,
    shouldThrowError = false,
  },
  dependencies = []
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  
  // Add mounted ref to track component lifecycle
  const mountedRef = useRef(true);
  // Reference to store the current request
  const currentRequestRef = useRef(null);

  useEffect(() => {
    mountedRef.current = true;
    
    const fetchData = async () => {
      if (!shouldExecute || !mountedRef.current) {
        return;
      }

      // Cancel any in-flight request
      if (currentRequestRef.current) {
        currentRequestRef.current.cancel();
      }

      if (cacheData) {
        if (mountedRef.current) {
          setData(cacheData);
          setIsLoading(false);
          setIsFetching(false);
        }
        return;
      }

      if (requiresAuthentication && !isAuthenticated) {
        if (mountedRef.current) {
          setIsLoading(false);
        }
        return;
      }

      if (isFetching) {
        return;
      }

      try {
        if (mountedRef.current) {
          setIsLoading(true);
          setIsFetching(true);
        }

        let token = null;
        if (requiresAuthentication) {
          token = await getAccessTokenSilently();
        }

        const request = REST.get({ 
          name, 
          params, 
          mockFileName, 
          shouldThrowError, 
          token
        });

        // Store the current request
        currentRequestRef.current = request;

        const response = await request;

        // Only update state if component is still mounted and this is still the current request
        if (mountedRef.current && currentRequestRef.current === request) {
          setData(response);
          setIsLoading(false);
          setIsFetching(false);
          if (callback) {
            callback(response);
          }
        }
      } catch (err) {
        // Only set error state if component is still mounted and error wasn't from cancellation
        if (mountedRef.current && !axios.isCancel(err)) {
          setIsLoading(false);
          setIsFetching(false);
          setIsError(true);
          setError(err.response);
        }
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      mountedRef.current = false;
      // Cancel any in-flight request on cleanup
      if (currentRequestRef.current) {
        currentRequestRef.current.cancel();
      }
    };
  }, [...dependencies, shouldExecute]);

  // Simplified fetch method that uses the same logic
  const fetch = async () => {
    if (!mountedRef.current) return;

    if (requiresAuthentication && !isAuthenticated) {
      setIsLoading(false);
      return;
    }

    if (isFetching) {
      return;
    }

    try {
      if (mountedRef.current) {
        setIsLoading(true);
        setIsFetching(true);
      }

      // Cancel any in-flight request
      if (currentRequestRef.current) {
        currentRequestRef.current.cancel();
      }

      let token = null;
      if (requiresAuthentication) {
        token = await getAccessTokenSilently();
      }

      const request = REST.get({ 
        name, 
        params, 
        mockFileName, 
        shouldThrowError, 
        token
      });

      // Store the current request
      currentRequestRef.current = request;

      const response = await request;

      // Only update state if component is still mounted and this is still the current request
      if (mountedRef.current && currentRequestRef.current === request) {
        setData(response);
        setIsLoading(false);
        setIsFetching(false);
        if (callback) {
          callback(response);
        }
      }
    } catch (err) {
      if (mountedRef.current && !axios.isCancel(err)) {
        setIsLoading(false);
        setIsFetching(false);
        setIsError(true);
        setError(err.response);
      }
    }
  };

  // Return values
  return {
    data,
    isLoading,
    isError,
    error,
    fetch,
    cancel: () => {
      if (currentRequestRef.current) {
        currentRequestRef.current.cancel();
      }
    }
  };
};

export const useDebounceQuery = (req, debounceTime, leading) => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    const requestId = uuidv4();
    const request = REST.get(req).then((res) => {
      if (typeof res === 'undefined') {
        setIsLoading(false);
        setResponse(null);
        return;
      }
      setResponse(res);
      setPendingRequests([]);
      setIsLoading(false);
    });

    pendingRequests.forEach((r) => r.request.cancel());
    setPendingRequests([{ request, id: requestId }]);
  };

  const fetchWithDebounce = useDebouncedCallback(() => fetchData(req), debounceTime, { leading });

  return [fetchWithDebounce, response, isLoading];
};
