import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import ServerContext from '~src/serverContext';
import { useGet } from '~hooks/useQuery';

import { SkeletonSimple } from '~components/skeleton';
import Collapsible from '~components/collapsible';
import { Suspense } from '~components/suspense';
import Socials from './socials';

const CITIES_COUNT = 24;
const NEIGHBORHOODS_COUNT = 12;

const SIDEBAR_CITIES_COUNT = 12;
const SIDEBAR_NEIGHBORHOODS_COUNT = 12;

const CityLink = ({ city }) => {
  return (
    <Link to={`/${city.city_slug}`} className="c-vertical-nav__item">
      {city.city_name}
    </Link>
  );
};

const CityLinkSkeleton = () => {
  return (
    <div className="c-vertical-nav__item city-link__skeleton">
      <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__footer-item" />
    </div>
  );
};

const CitiesSkeleton = ({ count = CITIES_COUNT }) => {
  return Array(count)
    .fill()
    .map((item, index) => <CityLinkSkeleton key={index} />);
};

const CitiesList = ({ cities, count = CITIES_COUNT }) => {
  if (!cities || !cities.length || !count) {
    return null;
  }

  return cities.slice(0, count).map((city) => {
    return <CityLink key={city.city_slug} city={city} />;
  });
};

const Neighborhoods = ({ count = NEIGHBORHOODS_COUNT }) => {
  const intl = useIntl();
  const cache = useContext(ServerContext);
  const urlLocation = useLocation();
  
  const serverLocation = cache.get('serverLocation');
  const initialSlug = serverLocation?.slug || '';
  
  const [slug, setSlug] = useState(initialSlug);

  useEffect(() => {
    const pathSlug = urlLocation.pathname.substring(1).toLowerCase().replaceAll(/\/$/g, '');
    setSlug(pathSlug);
  }, [urlLocation.pathname]);

  const { data: neighborhoods, isLoading } = useGet({
    name: `neighbour`,
    params: {
      slug,
    },
    cacheData: cache.get(`serverNeighborhoods${slug}`),
    shouldExecute: !!slug,
  });

  return (
    <div className="c-footer__column c-footer__column_neighborhoods">
      <Collapsible.Wrapper isExpanded={true} forScreen="mobile" className="c-footer__collapsible">
        <Collapsible.Header>
          <h3 className="l-headline--tertiary c-footer__headline">
            {intl.formatMessage({ id: 'components.footer.cities.neighborhoods', defaultMessage: 'Neighborhoods' })}
          </h3>
        </Collapsible.Header>
        <Collapsible.Content>
          <div className="c-vertical-nav c-footer__neighborhoods">
            <Suspense fallback={<CitiesSkeleton count={count} />} isLoading={isLoading}>
              <CitiesList cities={neighborhoods?.data?.neighbour_cities} count={count} />
            </Suspense>
          </div>
        </Collapsible.Content>
      </Collapsible.Wrapper>
    </div>
  );
};

const Popular = ({ count = CITIES_COUNT }) => {
  const intl = useIntl();
  const cache = useContext(ServerContext);
  const { data: cities, isLoading } = useGet({
    name: `cities`,
    cacheData: cache.get(`serverPopularCities`),
  });

  if (!isLoading && (!cities?.data || cities?.data?.length == 0)) {
    return null;
  }

  return (
    <div
      className={cx('c-footer__column', {
        'c-footer__column--large': count === CITIES_COUNT,
        'c-footer__column--med': count <= 18,
      })}
    >
      <Collapsible.Wrapper isExpanded={true} forScreen="mobile" className="c-footer__collapsible">
        <Collapsible.Header>
          <h3 className="l-headline--tertiary c-footer__headline">
            {intl.formatMessage({ id: 'components.footer.cities.popular', defaultMessage: 'Popular cities' })}
          </h3>
        </Collapsible.Header>
        <Collapsible.Content>
          <div
            className={cx('c-vertical-nav', {
              'c-footer__locations--large': count === CITIES_COUNT,
              'c-footer__locations--med': count <= 18,
            })}
          >
            <Suspense fallback={<CitiesSkeleton count={count} />} isLoading={isLoading}>
              <CitiesList cities={cities?.data} count={count} />
            </Suspense>
          </div>
        </Collapsible.Content>
      </Collapsible.Wrapper>
    </div>
  );
};

const Cities = ({ displayNeighborhoods }) => {
  return (
    <div className="c-footer__cities">
      <div className="l-container">
        <div className="c-footer__columns">
          {displayNeighborhoods && <Neighborhoods count={NEIGHBORHOODS_COUNT} />}
          <Popular count={displayNeighborhoods ? 12 : CITIES_COUNT} />
        </div>
      </div>
    </div>
  );
};


const SidebarPopularCities = () => {
  const intl = useIntl();
  const cache = useContext(ServerContext);
  const { data: cities, isLoading } = useGet({
    name: 'cities',
    cacheData: cache.get(`serverPopularCities`),
  });

  if (!isLoading && (!cities?.data || cities.data.length === 0)) {
    return null;
  }

  return (
    <div className="sidebar-section">
      <h3 className="sidebar-title">
        {intl.formatMessage({ id: 'components.footer.cities.popular' })}
      </h3>
      <Suspense fallback={<CitiesSkeleton count={10} />} isLoading={isLoading}>
        <div className="sidebar-cities-list">
          {cities?.data?.slice(0, SIDEBAR_CITIES_COUNT).map(city => (
            <CityLink key={city.city_slug} city={city} />
          ))}
        </div>
      </Suspense>
    </div>
  );
};

const SidebarNeighborhoods = () => {
  const intl = useIntl();
  const cache = useContext(ServerContext);
  const urlLocation = useLocation();
  
  const serverLocation = cache.get('serverLocation');
  const initialSlug = serverLocation?.slug || '';
  
  const [slug, setSlug] = useState(initialSlug);

  // Add mounted ref to track component lifecycle
  const isMounted = useRef(true);

  useEffect(() => {
    // Set mounted ref to true when component mounts
    isMounted.current = true;
    
    // Cleanup function to set mounted ref to false when unmounting
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const pathSlug = urlLocation.pathname.substring(1).toLowerCase().replace(/\/+$/g, '');
    // Only update state if component is still mounted
    if (isMounted.current) {
      setSlug(pathSlug);
    }
  }, [urlLocation.pathname]);

  const { data: neighborhoods, isLoading } = useGet({
    name: 'neighbour',
    params: { slug },
    cacheData: cache.get(`serverNeighborhoods${slug}`),
    shouldExecute: !!slug
  });

  if (!isLoading && (!neighborhoods?.data?.neighbour_cities || neighborhoods.data.neighbour_cities.length === 0)) {
    return null;
  }

  return (
    <div className="sidebar-section">
      <h3 className="sidebar-title">
        {intl.formatMessage({ id: 'components.footer.cities.neighborhoods' })}
      </h3>
      <Suspense fallback={<CitiesSkeleton count={SIDEBAR_NEIGHBORHOODS_COUNT} />} isLoading={isLoading}>
        <ul className="sidebar-cities-list">
          {neighborhoods?.data?.neighbour_cities?.slice(0, SIDEBAR_NEIGHBORHOODS_COUNT).map(city => (
            <CityLink key={city.city_slug} city={city} />
          ))}
        </ul>
      </Suspense>
    </div>
  );
};

const SidebarCities = ({ displayNeighborhoods }) => {
  return (
    <div className="sidebar-cities">
      <SidebarPopularCities />
      {displayNeighborhoods && <SidebarNeighborhoods />}
    </div>
  );
};

export { SidebarCities };

export default Cities;
