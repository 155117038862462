import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useFormContext, Controller } from 'react-hook-form';

import ValidationSchemaContext from './validationSchemaContext';

const withControlled =
  (Component) =>
  ({ name, showErrors = true, defaultValue, onStartValidation = () => {}, onEndValidation = () => {}, ...props }) => {
    const intl = useIntl();
    const { errors, control } = useFormContext();
    const validationSchema = useContext(ValidationSchemaContext);

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          validate: (val) => {
            onStartValidation();
            return validationSchema
              .validateAt(name, {
                [name]: val,
              })
              .then(() => true)
              .catch((err) => intl.formatMessage({ id: err.message }, err.params))
              .finally(() => onEndValidation());
          },
        }}
        render={({ onChange, value, name, ref, ...rest }) => (
          <Component
            name={name}
            inputRef={ref}
            // ref={ref}
            value={value}
            onChange={onChange}
            errors={showErrors && errors && errors[name] ? errors[name] : null}
            isInvalid={errors[name]}
            {...props}
            {...rest}
          />
        )}
      />
    );
  };

export default withControlled;
