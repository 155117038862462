import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { usePopper } from 'react-popper';
import './style.scss';

import Toggle from './toggle';
import Content from './content';

const ContextMenu = ({
  className,
  optionsClassName,
  isLoading,
  placement = 'bottom',
  value,
  visible,
  onVisibleChange = () => { },
  onBlur = () => { },
  hideOnBlur = true,
  modifiers,
  children,
  optionsWrapperRef = null,
}) => {
  const referenceRef = useRef();
  const popperRef = useRef(null);

  const { styles, attributes, forceUpdate } = usePopper(referenceRef.current, popperRef.current, {
    placement,
    modifiers,
  });

  const [frontRefreshed, setFrontRefreshed] = useState(false);

  const show = () => onVisibleChange(true);
  const hide = () => onVisibleChange(false);

  const handleBlur = () => {
    if(hideOnBlur) {
      hide();
    }
    onBlur();
  }

  useEffect(() => {
    // Reset frontRefreshed when visibility changes to false
    if (!visible) {
      setFrontRefreshed(false);
    }
    // Only update when becoming visible
    else if (visible && !frontRefreshed && forceUpdate) {
      // Schedule the update for the next frame
      requestAnimationFrame(() => {
        forceUpdate();
        setFrontRefreshed(true);
      });
    }
  }, [visible, frontRefreshed, forceUpdate]);

  return (
    <>
      <div
        ref={referenceRef}
        onClick={visible ? hide : show}
        className={cx('c-dropdown', {
          'c-dropdown--loading': isLoading,
          'c-dropdown--placeholder': isEmpty(value),
          [className]: !!className,
        })}
      >
        {children.filter((child) => child.type === Toggle)}
      </div>

      {visible && (<div className="c-dropdown__overlay" onClick={handleBlur} />)}

      {!visible && (
        <div ref={popperRef} className="c-dropdown--invisible c-dropdown__options">
          {children.filter((child) => child.type === Content)}
        </div>
      )}

      {visible
        ? ReactDOM.createPortal(
          <>

            <div
              ref={popperRef}
              className={cx('c-dropdown__options', {
                [optionsClassName]: !!optionsClassName,
              })}
              style={styles.popper}
              {...attributes.popper}
            >
              <div
                className={cx('c-dropdown__options__wrapper', {
                  visible,
                })}
                style={styles.offset}
                ref={optionsWrapperRef}
              >
                {children.filter((child) => child.type === Content)}
              </div>
            </div>
          </>,
          document.body
        )
        : null}
    </>
  );
};

export default ContextMenu;
